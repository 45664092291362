import { createStore } from 'vuex'

export default createStore({

    state: {
        setLang:''
    },
    mutations: {
        setLang: (state, setLang) => {
            state.setLang = setLang;
        },
    },
    getters: {
        setLang: state => state.setLang,
    },
    actions: {
        fetchLang (context) {
            let setLang = localStorage.getItem('language');
            context.commit('setLang', setLang);
        }
          
    },

})
