<template>
    <li class="nav__item" id="lang_larg">
      <a href="#" class="sub_menu nav__link">{{ language=="en"? $t('English') : $t('Arabic') }}</a>
      <i class="fa fa-caret-down icon_drop"></i>
      <ul>
          <li>
            <a  v-on:click="selected_language='ar'" class="nav__link">{{$t('Arabic')}}</a>
          </li>
          <li>
            <a v-on:click="selected_language='en'" class="nav__link">{{$t('English')}}</a>
          </li>
      </ul>
    </li>
</template>

<script>
import { i18n } from '../lang';

export default {
    name:'SelectLang',
    data(){
        return{
            selected_language: this.selected_language,
            language:localStorage.getItem('language')
        }
    },
    watch:{
        selected_language(new_lang, old_lang){
            localStorage.setItem('language' , new_lang );
            this.language = localStorage.getItem('language')
            document.documentElement.setAttribute('lang', new_lang);
            i18n.setLocale(this.language);
            this.$store.dispatch('fetchLang');
        }
    }
}
</script>

<style scoped>
    .nav__item ul{
        position: absolute;
        background-color: #18130c00;
        left: 0;
        display: none;
        text-align: center;
        width: 150px;
    }
    .nav__item:hover ul{
        display: block;
    }
    .nav__item ul li{
        display: block;
        width: 140px;
    }
    .main-nav__main-navigation .main-nav__navigation-box>li ul {
        width: 140px;
    }
    .nav__item ul li:hover{
    background: #272023;
    }
    .header .nav li a {
        line-height: 3.5;
    }
    @media screen and (max-width: 767px) {
        .nav__item ul{
            position: relative;
        }
        .nav__item ul li{
            display: block;
            width: 140px;
            padding: 10px;
        }
    }
    .icon_drop{
        position: relative;
        color: #9ca3a9;
        left: 5px;
        top:2px;
    }
    .nav__link {
        text-transform: capitalize;
        color: #9ca3a9;
        font-size: 18px;
        font-weight: 500;
        transition: all 500ms ease;
        position: relative;
        display: inline;
    }
    [lang=ar] .nav__item ul{
        left: auto;
    }
    [lang=ar] .icon_drop{
        right: 5px;
    }
</style>