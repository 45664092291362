<template>
   <div>
    <footer class="site-footer">
      <img src="../assets/images/shapes/footer-shape-1a.png" class="site-footer__shape-1" alt="">
      <img src="../assets/images/shapes/footer-shape-2a.png" class="site-footer__shape-2" alt="">
      <img src="../assets/images/shapes/footer-shape-3.png" class="site-footer__shape-3" alt="">
      <div class="site-footer__upper">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <div class="footer-widget footer-widget__about">
                <router-link :to='{name:"home"}' class="footer-widget__logo"><img src="../assets/images/logo-dark.svg" width="105" alt="" /></router-link>
                <p>{{$t('footerText')}}</p>
              </div><!-- /.footer-widget -->
            </div><!-- /.col-lg-4 -->
            <div class="col-lg-4">
              <div class="footer-widget footer-widget__links">
                <h3 class="footer-widget__title"></h3>
                <div class="footer-widget__links-wrap">
                  <ul class="list-unstyled mt-sm-4">
                    <li><router-link @click="scrollup" :to='{name:"about"}'>{{$t('About')}}</router-link></li>
                    <li><router-link @click="scrollup" :to='{name:"contact"}'>{{$t('Contact')}}</router-link></li>
                  </ul><!-- /.list-unstyled -->
                  <ul class="list-unstyled mt-sm-4">
                    <li><router-link @click="scrollup" :to='{name:"home", hash:"#services"}'>{{$t('Services')}}</router-link></li>
                    <li><router-link @click="scrollup" :to='{name:"billboardmap"}'>{{$t('BillBoard')}}</router-link></li>
                  </ul><!-- /.list-unstyled -->
                </div><!-- /.footer-widget__links-wrap -->
              </div><!-- /.footer-widget footer-widget__links -->
            </div><!-- /.col-lg-4 -->
            <div class="col-lg-4">
              <div class="site-footer__social">
                <a class="fab fa-facebook-f" href="https://www.facebook.com/alkhuyol.company" target="_blank"></a>
                <a class="fab fa-instagram" href="https://www.instagram.com/Alkhuyol.company52/" target="_blank"></a>
                <a class="fa-regular fa-envelope" href="mailto:info@alkhuyol.com"></a>
                <a class="fa-solid fa-phone" href="https://wa.me/+9647807777024" target="_blank"></a>
              </div>
            </div><!-- /.col-lg-4 -->
          </div><!-- /.row -->
        </div><!-- /.container -->
      </div><!-- /.site-footer__upper -->
      <div class="site-footer__bottom">
        <div class="container">
          <div class="">
            <!-- /.site-footer__social -->
            <p style="text-align: center;color:white;">© <span id="year"></span> {{$t('all copyrights reserved Stallions Advertising Company')}}</p>
          </div><!-- /.inner-container -->
        </div><!-- /.container -->
      </div>
    </footer>
    <a href="#" data-target="html" class="scroll-to-target scroll-to-top"><i class="fa fa-angle-up"></i></a>
    <div class="side-menu__block">
        <div class="side-menu__block-overlay custom-cursor__overlay">
          <div class="cursor"></div>
          <div class="cursor-follower"></div>
        </div><!-- /.side-menu__block-overlay -->
        <div class="side-menu__block-inner ">
        <div class="side-menu__top justify-content-end">
          <a href="#" class="side-menu__toggler side-menu__close-btn"><img src="../assets/images/shapes/close-1-1.png" alt=""></a>
        </div><!-- /.side-menu__top -->
        <nav class="mobile-nav__container">
			<ul class="one-page-scroll-menu main-nav__navigation-box">
				<li class="current scrollToLink">
					<router-link :to='{name:"home"}'>{{$t('Home')}}</router-link>
				</li>
				<li class="dropdown scrollToLink">
					<router-link class="active-execlude" :to='{name:"home", hash:"#services"}'>
						{{$t('Services')}}
						<button class="dropdown-btn"><i class="fa fa-angle-right"></i></button>
					</router-link>
					<ul>
						<li v-for="service in services" :key="service.name">
							<router-link :to="{ name:'services', params:{id: service.id} }">{{service?.translations.name[lang]}}</router-link>
						</li>
					</ul><!-- /.sub-menu -->
				</li>                        
				<li class="dropdown scrollToLink">
					<router-link :to='{name:"billboardmap"}'>{{$t('BillBoard')}}
						<button class="dropdown-btn"><i class="fa fa-angle-right"></i></button>
					</router-link>
					<i class="fa fa-caret-down icon_drop hh"></i>
					<ul>
						<li class="pb-0 pt-1">
							<router-link :to='{name:"billboardmap"}'>{{$t('BillBoard Map')}}</router-link>
						</li>
						<li>
							<router-link :to='{name:"manufactures"}'>{{$t('Manufacture BillBoard')}}</router-link>
						</li>
					</ul><!-- /.sub-menu -->
				</li>
				<li class="scrollToLink">
					<router-link :to='{name:"about"}'>{{$t('About')}}</router-link>
				</li>
				<li class="scrollToLink">
					<router-link :to='{name:"contact"}'>{{$t('Contact')}}</router-link>
				</li>
				<SelectLang class="lang_larg"/>
			</ul>
          	<SelectLang style="list-style:none"/>
          <!-- content is loading via js -->
        </nav>
        <div><img src="../assets/images/logo-light.svg" alt="" style="width:200px;max-width:100%;  margin:auto; display:block;"></div>
        <p style="text-align:center;" class="mt-5">© <span id="year"></span> all copyrights reserved Stallions Advertising Company</p>
        <!-- /.side-menu__content -->
       </div><!-- /.side-menu__block-inner -->
    </div>

  </div>
</template>

<script>
import SelectLang from '@/components/SelectLang.vue'
import { api_url } from '@/constants';
import axios from 'axios';

export default {
    name:'Footer',
	data(){
		return{
			services:[],
			lang:localStorage.getItem('language')
		}
	},
    components:{
      SelectLang
    },
    methods:{
      scrollup(){
        window.scrollTo(0,0);
      }
    },
	mounted(){
        axios.get(`${api_url}/api/services`)
            .then((response) => {
                this.services = response.data.data;
            });
    }
}

</script>

<style>

</style>