<template>
    <div class="preloader">
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>

    <header class="main-nav__header-one" style="position: sticky; top: 0;" id="header">
        <nav class="header-navigation stricky original">
            <div class="container">
                <!-- Brand and toggle get grouped for better mobile display -->
                <div class="main-nav__logo-box">
                    <router-link :to='{name:"home"}' class="main-nav__logo">
                        <img class="light-logo" src="../assets/images/logo-light.svg" width="105" alt="" />
                        <img class="dark-logo" src="../assets/images/logo-dark.svg" width="105" alt="" />
                    </router-link>
                    <a href="#" class="side-menu__toggler"><i class="fa fa-bars"></i>
                        <!-- /.smpl-icon-menu --></a>
                </div><!-- /.logo-box -->
                <!-- Collect the nav links, forms, and other content for toggling -->
                <div class="main-nav__main-navigation">
                    <ul class="one-page-scroll-menu main-nav__navigation-box">
                        <li class="scrollToLink">
                            <router-link :to='{name:"home"}'>{{$t('Home')}}</router-link>
                        </li>
                        <li class="dropdown scrollToLink">
                            <router-link class="active-execlude" :to='{name:"home", hash:"#services"}'>{{$t('Services')}}</router-link>
                            <ul>
                                <li v-for="service in services" :key="service.name">
                                    <router-link :to="{ name:'services', params:{id: service.id} }">{{service?.translations.name[lang]}}</router-link>
                                </li>
                            </ul><!-- /.sub-menu -->
                        </li>                        
                        <li class="dropdown scrollToLink">
                            <router-link :to='{name:"billboardmap"}'>{{$t('BillBoard')}}</router-link>
                            <i class="fa fa-caret-down icon_drop hh"></i>
                            <ul>
                                <li class="pb-0 pt-1">
                                    <router-link :to='{name:"billboardmap"}'>{{$t('BillBoard Map')}}</router-link>
                                </li>
                                <li>
                                    <router-link :to='{name:"manufactures"}'>{{$t('Manufacture BillBoard')}}</router-link>
                                </li>
                            </ul><!-- /.sub-menu -->
                        </li>
                        <li class="scrollToLink">
                            <router-link :to='{name:"about"}'>{{$t('About')}}</router-link>
                        </li>
                        <li class="scrollToLink">
                            <router-link :to='{name:"contact"}'>{{$t('Contact')}}</router-link>
                        </li>
                        <SelectLang class="lang_larg"/>
                    </ul>
                </div><!-- /.navbar-collapse -->
            </div>
        <!-- /.container -->
        </nav>
    </header>
</template>

<script>
import {services} from '@/data'
import SelectLang from '@/components/SelectLang.vue'
import axios from 'axios';
import { api_url } from '@/constants';
export default {
    name:'Navbar',
    components:{ SelectLang },
    data(){
        return{
            services:'',
            lang:localStorage.getItem('language')
        }
    },
    mounted(){
        axios.get(`${api_url}/api/services`)
            .then((response) => {
                this.services = response.data.data;
            });
            let scrollpos = window.scrollY
            const nav = document.querySelector("nav")
            const scrollChange1 = 10

            const add_class_on_scroll1 = () => nav.classList.add("nav-class")
            const remove_class_on_scroll1 = () => nav.classList.remove("nav-class")

            window.addEventListener('scroll', function() { 
            scrollpos = window.scrollY;
            

            if (scrollpos >= scrollChange1) { add_class_on_scroll1() }
            else { remove_class_on_scroll1() }
            
            })

            const scrollChange = 50

            const add_class_on_scroll = () => header.classList.add("scroll-header")
            const remove_class_on_scroll = () => header.classList.remove("scroll-header")

            window.addEventListener('scroll', function() { 
            scrollpos = window.scrollY;
            

            if (scrollpos >= scrollChange) { add_class_on_scroll() }
            else { remove_class_on_scroll() }
            
            })
    }
}
</script>

<style>
    .nav-class{
        transform: translateY(-80px);
    }
    .scroll-header{
        transform: translateY(70px);
        transition: .6s;
    }
    .scroll-header .original{
        background-color: #000;
        /* height: 0; */
    }
    .scroll-header .original .main-nav__main-navigation .main-nav__navigation-box>li>a {
        color: #ffffff;
    }
    .scroll-header .dark-logo{
        display: block;
    }
    .scroll-header .light-logo{
        display: none;
    }
    .original{
        height: 112px;
        background-color: white;
    }
    .original .main-nav__main-navigation .main-nav__navigation-box>li>a {
        color: #240f0f;
    }
    /* .original .main-nav__main-navigation .main-nav__navigation-box>li.current>a{
        color: #240f0f;
    } */
    /* .original .main-nav__main-navigation .main-nav__navigation-box>li:hover>a, .main-nav__main-navigation .main-nav__navigation-box>li.current>a, .main-nav__main-navigation .main-nav__navigation-box>li.current-menu-item>a {
        color: #ee464b;
    } */
    @media screen and (max-width: 1199px) {
        .lang_larg{
            display: none;
        }
    }
    @media (max-width: 768px) {
        .original {
            height: 100%;
        }
    }
    .original .main-nav__main-navigation .main-nav__navigation-box>li>.active-far:not(.active-execlude), .original .main-nav__main-navigation .main-nav__navigation-box>li.current>.active-far:not(.active-execlude) {
        color:#ee464b;
    }
    .icon_drop{
        position: relative;
        color: #9ca3a9;
        left: 5px;
        top:2px;
    }
    [lang=ar] .icon_drop{
        right: 5px;
    }
    .dark-logo{
        display:none;
    }
    .light-logo{
        display:inline-block;
    }
    .stricky-fixed .dark-logo{
        display:inline-block;
    }
    .stricky-fixed .light-logo{
        display:none;
    }
    i.hh{
        display: none;
    }
</style>