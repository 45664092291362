import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/bootstrap.min.css'
import './assets/css/animate.min.css'
import './assets/css/fontawesome-all.min.css'
import './assets/css/bootstrap-datepicker.min.css'
import './assets/css/bootstrap-select.min.css'
// import'./assets/css/jquery.mCustomScrollbar.min.css'
import './assets/css/owl.carousel.min.css'
import './assets/css/owl.theme.default.min.css'
import './assets/css/zimed-icon.css'
// import './assets/css/jquery.bxslider.min.css'
import './assets/css/magnific-popup.css'
import './assets/css/style.css'
import './assets/css/responsive.css'

import './assets/js/jquery.min.js'
// import './assets/js/bootstrap.bundle.min.js'
import './assets/js/TweenMax.min.js'
// import './assets/js/jquery.mCustomScrollbar.concat.min.js'
// import './assets/js/bootstrap-datepicker.min.js'
import './assets/js/jquery.easing.min.js'
// import './assets/js/bootstrap-select.min.js'
// import './assets/js/jquery.validate.min.js'
// import './assets/js/waypoints.min.js'
import './assets/js/wow.js'
import './assets/js/jquery.counterup.min.js'
// import './assets/js/owl.carousel.min.js'
import './assets/js/jquery.bxslider.min.js'
// import './assets/js/jquery.magnific-popup.min.js'
import './assets/js/jquery.ajaxchimp.min.js'
import {i18n} from './lang'
import store from './store'

let language = localStorage.getItem("language")??'en';
if(!['en','ar'].includes(language)) language='en';
localStorage.setItem("language", language );
i18n.setLocale(language);
document.documentElement.setAttribute('lang', language);
createApp(App).use(router).use(i18n).use(store).mount('#app')