import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue')
  },
  {
    path: '/billboardmap',
    name: 'billboardmap',
    component: () => import(/* webpackChunkName: "billboardmap" */ '../views/BillBoardMapView.vue')
  },
  {
    path: '/manufactures',
    name: 'manufactures',
    component: () => import(/* webpackChunkName: "manufactures" */ '../views/ManufacturesView.vue')
  },
  {
    path: '/manufactures/:id',
    name: 'manufacture',
    component: () => import(/* webpackChunkName: "manufacture" */ '../views/ManufactureView.vue')
  },
  {
    path: '/services/:id',
    name: 'services',
    component: () => import(/* webpackChunkName: "service" */ '../views/ServiceView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // linkActiveClass: 'active-far',
  linkExactActiveClass:'active-far',
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  },
})

export default router
